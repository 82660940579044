.react-grid-item {
  transition: all 200ms ease;
}

.react-grid-item > .react-resizable-handle {
  width: 30px;
  height: 30px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "↘";
  font-size: 20px;
  bottom: 7px;
  right: 0px;
  height: 20px;
  width: 19px;
  border-right-width: 0;
  border-bottom-width: 0;
  opacity: 0.5;
}

.drag-handle {
  cursor: grab;
}

.dragging {
  cursor: grabbing !important;
}

.react-grid-item.react-grid-placeholder {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Ensure layout takes full width */
.layout {
  width: 100%;
  display: block;
}

/* Fix for proper column display */
.react-grid-layout {
  position: relative;
  width: 100% !important; /* Force full width */
}

.react-grid-item {
  box-sizing: border-box;
  padding: 4px;
  position: absolute;
}

/* Make sure children of grid items fill their container */
.react-grid-item > * {
  width: 100%;
  height: 100%;
}

.grid-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.drag-handle {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background: transparent;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s ease, background-color 0.2s ease;
}

.drag-handle:hover {
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 1;
}

.grid-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
